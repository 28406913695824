import { FC } from "react";

const Policy: FC = () => {
    return (
        <main className="transition-all duration-500 ease-out overflow-auto relative" style={{height: "calc(100vh)"}}>
            <div className="p-[30px] text-[16px]">
                <img alt={""} src={'https://dntdurzwr12tp.cloudfront.net/template/trixgo_core/images/logo-mobile.png'} />
                <h2>Privacy Policy</h2>
                <p className="mb-5">PRIVACY NOTICE</p>
                <p className="mb-3">Last updated Jan 01, 2024</p>
                <p className="mb-3">This privacy notice for Trixgo JSC (doing business as Trixgo) (Trixgo, we, us, or our), describes how and why we might collect, store, use, and/or share process your information when you use our services Services, such as when you:</p>
                <p className="mb-3">Download and use our mobile application (Trixgo), or any other application of ours that links to this privacy notice
Engage with us in other related ways, including any sales, marketing, or events</p>
                <p className="mb-3">Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at contact.trixgo@gmail.com.</p>
                <p className="mb-3">What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Trixgo and the Services, the choices you make, and the products and features you use.</p>
                <p className="mb-3">Do we process any sensitive personal information? We do not process sensitive personal information.</p>
                <p className="mb-3">Do we receive any information from third parties? We do not receive any information from third parties.</p>
                <p className="mb-3">How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
                <p className="mb-3">In what situations and with which types of parties do we share personal information? We may share information in specific situations and with specific categories of third parties.</p>
                <p className="mb-3">How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
                <p className="mb-3">What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
                <p className="mb-3">How do you exercise your rights? The easiest way to exercise your rights is by filling out our data subject request form available here: contact.trixgo@gmail.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <p className="mb-3">Want to learn more about what Trixgo does with any information we collect? Contact to contact.trixgo@gmail.com.</p>

                <h2 className="mb-5">1. WHAT INFORMATION DO WE COLLECT?</h2>
                <p className="mb-3">Personal information you disclose to us</p>
                <p className="mb-3">In Short: We collect personal information that you provide to us.</p>
                <p className="mb-3">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                <p className="mb-3">Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                <p className="mb-3">names</p>
                <p className="mb-3">email addresses</p>
                <p className="mb-3">Sensitive Information. We do not process sensitive information.</p>

                <h2 className="mb-5">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p className="mb-3">In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>

                <h2 className="mb-5">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
</h2>
                <p className="mb-3">In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
</p>

                <h2 className="mb-5">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p className="mb-3">In Short: We do not share information with third parties.</p>

                <h2 className="mb-5">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                <p className="mb-3">In Short: We may use cookies and other tracking technologies to collect and store your information.</p>

                <h2 className="mb-5">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p className="mb-3">In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

                <h2 className="mb-5">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p className="mb-3">In Short: We aim to protect your personal information through a system of organizational and technical security measures.</p>

                <h2 className="mb-5">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p className="mb-3">In Short: In some regions, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>

                <h2 className="mb-5">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p className="mb-3">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (DNT) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                <h2 className="mb-5">10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p className="mb-3">In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

                <h2 className="mb-5">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p className="mb-3">If you have questions or comments about this notice, you may email us at contact.trixgo@gmail.com.</p>

                <h2 className="mb-5">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</h2>
                <p className="mb-3">You have the right to request access to the personal information we collect from you, change that information, or delete it</p>

                <h3 className="mb-5 uppercase">13. Some permissions require access before using the feature</h3>
                <p>android.permission.CAMERA,</p>
                <p>android.permission.RECORD_AUDIO,</p>
                <p>android.permission.READ_PHONE_STATE,</p>
                <p>android.permission.GET_ACCOUNTS,</p>
                <p>android.permission.READ_CONTACTS</p>
            </div>
        </main>
    );
};

export default Policy;