import { useContext } from "react";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CommentWithReply,
  CommentBody,
  Response,
  ListRecord,
  CommentDetail,
} from "typings/ppt/comment.d";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import CommentImageRepository from "apis/media/CommentImageRepository";
import CommentRepository from "apis/media/comment";
import { useMemo } from "react";
import AuthContext from "services/contexts/Auth";
import { User } from "typings/user";

type Payload<T = undefined> = {
  mediaID: string;
  fileID?: T;

  lastestID?: string | undefined;
  limit?: number | undefined;
  companyID?: string | undefined;
  parentID?: string | undefined;
  filter?: string | undefined;
  select?: string | undefined;
  populates?: string | undefined;
};

function useComments<T extends string | undefined>(payload: Payload<T>) {
  const key = [
    payload.fileID ? "list_comments_image" : "list_comments",
    payload.mediaID,
    payload.fileID,
  ];

  const {
    state: { user },
  } = useContext(AuthContext);

  const { mutateAsync: deleteCommentAsync, isLoading: isLoadingDelete } =
    useMutation(
      payload.fileID
        ? CommentImageRepository.deleteComment
        : CommentRepository.deleteComment
    );

  // const { mutateAsync: updateCommentAsync, isLoading: isLoadingUpdate } =
  //   useMutation(
  //     payload.fileID
  //       ? CommentImageRepository.updateComment
  //       : CommentRepository.updateComment
  //   );

  const {
    isLoading: isLoadingList,
    refetch: refetchListComments,
    data,
    fetchNextPage,
    fetchPreviousPage,
  } = useInfiniteQuery(
    key,
    async ({ pageParam = "" }) => {
      const response = payload.fileID
        ? await CommentImageRepository.listComments({
            lastestID: pageParam,
            mediaID: payload.mediaID,
            fileID: payload.fileID,
            // ...payload,
          })
        : await CommentRepository.listComments({
            lastestID: pageParam,
            mediaID: payload.mediaID,
            // ...payload,
          });

      return {
        data: {
          listRecords: response.data.listRecords.map((comment) => {
            const newCommnet: CommentWithReply = {
              ...comment,
              listReply: {
                data: comment.lastestReplyID ? [comment.lastestReplyID] : [],
                nextCursor: comment.lastestReplyID
                  ? comment.lastestReplyID._id
                  : null,
              },
            };
            return newCommnet;
          }),
          limit: response.data.limit,
          totalRecord: response.data.totalRecord,
          nextCursor: response.data.nextCursor,
        },
        error: response.error,
        message: response.message,
      } as Response<ListRecord<CommentWithReply>>;
    },
    {
      getNextPageParam: ({ nextCursor }: any) => {
        return nextCursor || undefined;
      },
      getPreviousPageParam({ data }) {
        return data.nextCursor;
      },
      refetchOnWindowFocus: false,
      enabled: true, // https://react-query.tanstack.com/guides/disabling-queries
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );

  const queryClient = useQueryClient();

  async function createComment<
    P extends CommentBody,
    C extends P["parentID"] extends string
      ? [{ page: number; commentIdx: number }]
      : []
  >(p: P, ...options: C) {
    try {
      const {
        error,
        data: _data,
        message,
        code,
      } = payload.fileID
        ? await CommentImageRepository.createComment(p as any)
        : await CommentRepository.createComment(p);
      // Promise delay two second
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      if (error || code) {
        if (message) return toast.error(message);
        throw new Error((_data as any)[0].message as string);
      } else
        queryClient.setQueryData<typeof data>(key, (old) => {
          if (old) {
            // Create reply comment;
            if (options[0]) {
              const { page, commentIdx } = options[0];
              (old.pages[page].data.listRecords[commentIdx] as CommentDetail)
                .amountCommentReply++;
              old.pages[page].data.listRecords[commentIdx].listReply.data.push({
                ..._data,
                author: user as User,
              });

              // Create main;
            } else {
              old.pages[0].data.totalRecord++;

              old.pages[old.pages.length - 1].data.listRecords.push({
                ..._data,
                author: user as User,
                listReply: { data: [], nextCursor: null },
              });
            }
          }
          return old;
        });
    } catch (error: unknown) {
      if (typeof error === "string") {
        toast.error(error);
      } else if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  }

  /**
   *
   * @param payload Comment payload
   * @returns Promise<void>
   */
  async function updateComment<
    P extends Omit<CommentBody, "parentID"> & {
      commentID: string;
      parentID: string;
      fileID?: string;
    },
    C extends {
      page: number;
      commentIdx: number;
      replyIdx?: number;
    }
  >(payload: P, { page, commentIdx, replyIdx }: C) {
    try {
      const {
        error,
        data: _data,
        message,
        code,
        // } = await updateCommentAsync(payload);
      } = payload.fileID
        ? await CommentImageRepository.updateComment(payload as any)
        : await CommentRepository.updateComment(payload);
      if (error || code) {
        if (message) return toast.error(message);
        throw new Error((_data as any)[0].message as string);
      } else
        queryClient.setQueryData<typeof data>(key, (old) => {
          if (old) {
            if (typeof replyIdx === "number") {
              // Update reply comment;
              old.pages[page].data.listRecords[commentIdx].listReply.data[
                replyIdx
              ] = {
                ...old.pages[page].data.listRecords[commentIdx].listReply.data[
                  replyIdx
                ],
                ..._data,
              };
            } else {
              // Update comment
              old.pages[page || 0].data.listRecords[commentIdx || 0] = {
                ...old.pages[page || 0].data.listRecords[commentIdx || 0],
                ..._data,
                listReply: { data: [], nextCursor: null },
              };
            }
          }
          return old;
        });

      // return data;
    } catch (error: unknown) {
      if (typeof error === "string") {
        toast.error(error);
      } else if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  }

  /**
   *
   * @param id comment id
   */
  const deleteComment = async (
    id: string,
    options: {
      page: number;
      commentIdx: number;
      replyIdx?: number;
    }
  ) => {
    try {
      const { error, data: _data, message } = await deleteCommentAsync(id);
      error && toast.error(message);
      if (error) {
        if (message) return toast.error(message);
        throw new Error((_data as any)[0].message as string);
      } else
        queryClient.setQueryData<typeof data>(key, (old) => {
          if (old) {
            if (typeof options.replyIdx === "number") {
              // Update reply comment;
              old.pages[options.page || 0].data.listRecords[
                options.commentIdx || 0
              ].listReply.data.splice(options.replyIdx || 0, 1);
              old.pages[options.page || 0].data.listRecords[
                options.commentIdx || 0
              ].amountCommentReply--;
            } else {
              // Update comment
              old.pages[0].data.totalRecord--;
              delete old.pages[options.page || 0].data.listRecords[
                options.commentIdx || 0
              ];
            }
          }
          return old;
        });
    } catch (error: unknown) {
      if (typeof error === "string") {
        toast.error(error);
      } else if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  /**
   *
   * @param lastestID Id of lastest reply comment
   * @param parentID Id of comment
   * @param page page index of comment list (useInfiniteQuery)
   * @param commentIdx index of page's comment (useInfiniteQuery)
   */
  const fetchReplyComment = async (
    lastestID: string | undefined | null,
    parentID: string,
    page: number,
    commentIdx: number
  ) => {
    if (!lastestID) return;
    const { error, data: _data } = payload.fileID
      ? await CommentImageRepository.listComments({
          lastestID,
          mediaID: payload.mediaID,
          parentID,
          fileID: payload.fileID,
        })
      : await CommentRepository.listComments({
          lastestID,
          mediaID: payload.mediaID,
          parentID,
        });

    if (!error && _data.listRecords.length) {
      queryClient.setQueryData<typeof data>(key, (old) => {
        if (old) {
          old.pages[page || 0].data.listRecords[
            commentIdx
          ].listReply.data.unshift(..._data.listRecords);
          old.pages[page || 0].data.listRecords[
            commentIdx
          ].listReply.nextCursor = _data.nextCursor;
        }
        return old;
      });
    }
  };

  const currentComment = useMemo(
    () =>
      data?.pages.reduce((acc, page) => page.data.listRecords.length + acc, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.pages.length, data?.pages[0].data.totalRecord]
  );

  return {
    currentComment,

    createComment,
    deleteComment,
    updateComment,

    isLoadingList,
    // isLoadingCreate,
    isLoadingDelete,
    // isLoadingUpdate,

    refetchListComments,
    fetchNextPage,
    fetchPreviousPage,
    data,

    fetchReplyComment,
  };
}

export default useComments;
