import API from "../../repository";
import { ITEM_URL } from '../';

import { Contact } from 'typings/items';

export const contactRepository = {
    fetchList: (params: Contact.ContactListParams): Promise<Contact.ContactListResponse> => fetchListAndInfo(params),
    fetchInfo: (params: Contact.ContactInfoParams) => fetchListAndInfo(params),
    insert: (payload: any) => insert(payload),
    fetchListByProperty: (params: any): Promise<any> => fetchListByProperty(params),
}

async function fetchListAndInfo(params: any) {
    const { data } = await API.get(
        `${ITEM_URL}/contacts`, {
        params: {
            // select: 'name sign description note type company phone email birthday modifyAt createAt address gender numberOfOrders remainLoyaltyPoints',
            // populates: {
            //     path: 'company position',
            //     select: 'sign name'
            // },
            ...params
        }
    })

    return data;
}

async function insert(payload: any): Promise<any> {
    const { data } = await API.post<any>(
        `${ITEM_URL}/contacts`, payload
    );

    return data;
}

async function fetchListByProperty(params : any) {
    const { data } = await API.get(`${ITEM_URL}/contacts/get-list-by-property`, {
        params: {
            ...params,
        }
    })
    return data
}