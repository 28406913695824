import { FC } from "react";

type Props = {
    className?: string;
};

const TriangleDown: FC<Props> = ({ className }) => {
    return (
        <svg className={className} width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 7L0.00480938 0.25L12.9952 0.25L6.5 7Z" fill="#C4C4C4"/>
        </svg>
    );
};

export default TriangleDown;
