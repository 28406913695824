const uploadFileTypes = {
  SET_UPLOAD_FILE: "SET_UPLOAD_FILE",
  SET_UPLOAD_IMAGE: "SET_UPLOAD_IMAGE",
  SET_UPLOAD_FILE_PROGRESS: "SET_UPLOAD_FILE_PROGRESS",
  SET_UPLOAD_IMAGE_PROGRESS: "SET_UPLOAD_IMAGE_PROGRESS",
  SUCCESS_UPLOAD_FILE: "SUCCESS_UPLOAD_FILE",
  SUCCESS_UPLOAD_IMAGE: "SUCCESS_UPLOAD_IMAGE",
  SET_ID_IMAGE_UPLOADED: "SET_ID_IMAGE_UPLOADED",
  SET_ID_FILE_UPLOADED: "SET_ID_FILE_UPLOADED",
  FAILURE_UPLOAD_FILE: "FAILURE_UPLOAD_FILE",
  FAILURE_UPLOAD_IMAGE: "FAILURE_UPLOAD_IMAGE",
  CANCEL_UPLOAD_FILE: "CANCEL_UPLOAD_FILE",
  CANCEL_UPLOAD_IMAGE: "CANCEL_UPLOAD_IMAGE",
  REMOVE_UPLOADED_FILE: "REMOVE_UPLOADED_FILE",
  REMOVE_UPLOADED_IMAGE: "REMOVE_UPLOADED_IMAGE",
  REMOVE_ALL_FILES: "REMOVE_ALL_FILES",
};

export default uploadFileTypes;
