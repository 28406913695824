const modalTypes = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_POST: "OPEN_POST",
  OPEN_COMMENT: "OPEN_COMMENT",
  OPEN_REPLY: "OPEN_REPLY",
  SET_INDEX: "SET_INDEX",
};

export default modalTypes;
