import { FC } from 'react';
import cn from 'classnames';

import downloadIcon from 'assets/images/icons8-download-48.png';

import { getFileIcon } from 'utils/file';
import { humanFileSize } from 'utils/converter';
import { FileProps, PostFileProps } from 'typings/common';

export const PostFile: FC<PostFileProps & React.HTMLAttributes<HTMLDivElement>> = ({ files, onRemove, ...props }) => {
	if (!files?.length) return <></>;
	const isHide = files.every(file => Object.keys(file).length === 1);
	if(isHide) return <></>;

	const handleDownload = (file: FileProps | File) => {
		if ('path' in file) {
			const a = document.createElement('a');
			a.href = `${process.env.REACT_APP_URL_S3}${file.path}`;
			a.download = file.nameOrg;
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	return (
		<>
			{
				files.map((d: any) => {
					if(!d || Object.keys(d).length === 1) return;

					const name 		= 'progress' in d ? d.file?.name : (d.file ? d.file?.nameOrg : d.nameOrg);
					const size 		= 'progress' in d ? d.file?.size : (d.file ? d.file?.size : d.size);
					const path 		= 'progress' in d ? d.file?.path : (d.file ? d.file?.path : d.path);
					const mimeType 	= 'progress' in d ? d.file?.mimeType : (d.file ? d.file?.mimeType : d.mimeType);
					const download 	= 'progress' in d ? d.file : (d.file ? d.file : d);
					const key 		= 'progress' in d ? d.id : d._id;
					const iconFile = getFileIcon(mimeType);

					return (
						<div key={key} {...props} className={cn("post__files-file flex pl-3.5 py-2.5 pr-6", props.className, { loading: 'progress' in d && d.progress < 100 })}>
							<div className="mr-3.5"><img src={iconFile} className="w-[35px] h-[35px]" alt="pdf icon" /></div>
							<div className="flex-1">
								<a href={`${process.env.REACT_APP_URL_S3}${path}`} target="_blank" rel="noreferrer" className="text-sm leading-4 mt-2.5">
									{name}
								</a>
								<p className="text-secondary-gray mt-2.5" style={{ lineHeight: "13.8px" }}>{humanFileSize(size)}</p>
							</div>
							<button type="button" className="btn-download text-primary" onClick={() => handleDownload(download)}>
								{
									'progress' in d && d.progress < 100
										? d.progress + '%'
										: <img src={downloadIcon} alt="download icon" />
								}
							</button>
							<button
								type="button"
								className={cn("close-btn absolute top -top-3.5 -right-3 bg-white rounded-3xl h-6 w-6 flex justify-center items-center", { "hidden": !onRemove })}
								onClick={() => onRemove && onRemove(d, 'FILE')}
							>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
					)
				})
			}
		</>
	)
}