import React, {FC, useCallback, useEffect, useRef, useState, useContext} from "react";
import {map as _map} from "lodash";
import {UseConversation} from "services/hooks/messages/useConversation";
import {ItemListConversation} from "typings/apis/messages/listConversation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "redux/reducer";
import ArrowDown from "assets/images/icon-arrow-down.svg";
import {ItemMessage} from "typings/apis/messages/listMessage";
import 'react-circular-progressbar/dist/styles.css';
import {Reminder, ReminderNoti} from "../Reminder";
import moment from "moment";
import angered from "assets/images/emoji/angered.png";
import heart from "assets/images/emoji/heart.png";
import laugh from "assets/images/emoji/laugh.png";
import like from "assets/images/emoji/like.png";
import sad from "assets/images/emoji/sad.png";
import surprise from "assets/images/emoji/surprise.png";
import {Poll, PollNoti} from "../Poll";
import {
    AddUsersNoti,
    PinMessageNoti,
    MyMessage,
    OthersMessage,
    NotificationCenter,
    ChangeNameNoti, ChangeAvatarNoti
} from "../ChatContent/RegularContent";
import { Oval } from  'react-loader-spinner'
import {updateUsersMissMessageToConversation} from "redux/reducer/messages/ListConversation";
import {UseListConversation} from "services/hooks/messages/useListConversation";
import {setIsReviewOldMessages} from "redux/reducer/messages/ListMessage";
import {NpsNoti} from "../Nps/NpsNoti";
import {MessageContext} from "../../../../../../shareComponents/layouts/MessagesLayoutV2";

export const listEmoji = [
    {
        id: 1,
        icon: like,
        value: 1
    },
    {
        id: 2,
        icon: heart,
        value: 2
    },
    {
        id: 3,
        icon: laugh,
        value: 3
    },
    {
        id: 4,
        icon: surprise,
        value: 4
    },
    {
        id: 5,
        icon: sad,
        value: 5
    },
    {
        id: 6,
        icon: angered,
        value: 6
    }
];

type Props = {
    chat: ItemListConversation
}

export const MainChatContent:FC<Props> = ({chat}) => {
    const {getListMessage} = UseConversation();
    const {markReadConversation} = UseListConversation();
    const dispatch = useDispatch();

    const currentUser = localStorage.getItem("currentUser")??"{}";
    const profileId = JSON.parse(currentUser)?._id;
    const {currentChat} = useContext(MessageContext);

    const conversationMessagesList = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [reachedTop, setReachTop] = useState(false);
    const messagesData = useSelector((state: RootState) => state.listMessage.data.listMessage[currentChat]);
    const fakeMessagesData = useSelector((state: RootState) => state.listMessage.data.listFakeMessage[currentChat]);
    const hasMoreData = useSelector((state: RootState) => state.listMessage.data.hasMoreData[currentChat]);
    const nextCursor = useSelector((state: RootState) => state.listMessage.data.nextCursor[currentChat]);
    const isLoading = useSelector((state: RootState) => state.listMessage.data.isLoading[currentChat]);

    const memoizedValue = useCallback(() => {
        const value = parseMessageData(messagesData);
        return _map(value.sort((a,b) => {
            if (moment(b.createAt).toDate().getTime() < moment(a.createAt).toDate().getTime()) {
                return 1
            }
            return -1
        }), (message, index) => renderMessage(message, value[index-1]))
    },[messagesData]);

    const memoizedFakeValue = useCallback(() => {
        const value = parseMessageData(fakeMessagesData);
        return _map(value.sort((a,b) => {
            if (moment(b.createAt).toDate().getTime() < moment(a.createAt).toDate().getTime()) {
                return 1
            }
            return -1
        }), (message, index) => renderMessage(message, null, false))
    },[fakeMessagesData]);

    const parseMessageData = (messagesData: {[key:string] : ItemMessage}) => {
        if (!messagesData) return [];
        return _map(Object.values(messagesData), message => message)
    }

    useEffect(() => {
        if (userUnreadMessage()>0) {
            markAsRead()
        }
        const conversationMessagesList = document.getElementById("conversation-messages-MessageList");
        if (conversationMessagesList) {
            conversationMessagesList.scrollTop = 0;
        }
        getListMessage({conversationID: currentChat})
    },[currentChat]);

    useEffect(() => {
        conversationMessagesList.current && conversationMessagesList.current.addEventListener("scroll", () => onScroll());
    },[conversationMessagesList]);

    useEffect(() => {
        if (reachedTop && !isLoading && hasMoreData) {
            getListMessage({lastestID: nextCursor, conversationID: currentChat});
            setReachTop(false);
        }
    }, [reachedTop, nextCursor])

    const markAsRead = () => {
        markReadConversation({
            conversationID: chat._id
        }).then(data => {
            dispatch(updateUsersMissMessageToConversation({
                conversationId: chat._id,
                usersMissMessage: data.usersMissMessage
            }))
        })
    }

    const userUnreadMessage = useCallback(() => {
        const userUnread = chat?.usersMissMessage?.find(unread => unread.userID === profileId)
        return userUnread?.amount??0
    }, [chat, profileId])

    const onScroll = () => {
        if (conversationMessagesList.current) {
            const { scrollTop, scrollHeight, clientHeight } = conversationMessagesList.current;
            dispatch(setIsReviewOldMessages({conversationId: currentChat, isReview: true}))
            if (scrollHeight + scrollTop - clientHeight < 300) {
                setReachTop(true)
            } else {
                setReachTop(false);
            }
            if (scrollTop >= 0) {
                dispatch(setIsReviewOldMessages({conversationId: currentChat, isReview: false}))
            }
        }
    };

    const scrollToBottom = () => {
        if (conversationMessagesList.current) {
            conversationMessagesList.current.scrollTo({top: conversationMessagesList.current.scrollHeight, behavior: "smooth" });
        }
    }

    const renderMessage = (message: ItemMessage, previousMessage: ItemMessage|null|undefined, shouldRenderDate = true) => {
        const messageCreatedAt = moment(message.createAt);
        const isOut = message.sender._id === profileId;
        let classOrigin = (isOut ? "is-out":"is-in");
        if (![0,1,2,7].includes(message.type)) {
            classOrigin = "is-middle"
        }
        return (
            <div key={message._id} id={`message-${message._id}`} className={(message.noAnimation?"":"bubble-animation") + " " + classOrigin}>
                {
                    shouldRenderDate&&previousMessage&&!messageCreatedAt.isSame(moment(previousMessage.createAt), 'date')?
                        <NotificationCenter content={messageCreatedAt.format("DD/MM/YYYY")}/>:
                        (
                            shouldRenderDate&&!previousMessage?
                                <NotificationCenter content={messageCreatedAt.format("DD/MM/YYYY")}/>:
                                null
                        )
                }
                {renderPrimaryMessage(message)}
            </div>
        )
    }
    const renderPrimaryMessage = (message: ItemMessage) => {
        switch (message.type) {
            case 0:
            case 1:
            case 2:
                if (message.sender._id === profileId) {
                    return <MyMessage context={MessageContext} message={message}/>
                } else {
                    return <OthersMessage context={MessageContext} message={message}/>
                }
            case 4:
                return <Reminder context={MessageContext} message={message}/>;
            case 5:
                return <Poll message={message} chat={chat} context={MessageContext}/>;
            case 7:
                if (message.sender._id === profileId) {
                    return <MyMessage context={MessageContext} message={message}/>
                } else {
                    return <OthersMessage context={MessageContext} message={message}/>
                }
            case 8:
                if (message.sender._id === profileId) {
                    return <MyMessage context={MessageContext} message={message}/>
                } else {
                    return <OthersMessage context={MessageContext} message={message}/>
                }
            case 101:
                return <AddUsersNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã thêm: <span class="text-black">${message.usersAssigned.map(user => user.bizfullname).join("; ")}</span>`}/>
            case 102:
                return <AddUsersNoti isLeave={true} content={`${(message.sender.bizfullname||message.sender.fullname)} đã xoá: <span class="text-black">${message.usersAssigned.map(user => user.bizfullname).join("; ")}</span>`}/>
            case 103:
                return <ChangeNameNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã đổi tên cuộc hội thoại`}/>;
            case 104:
                return <ChangeAvatarNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã đổi ảnh đại diện cuộc hội thoại`}/>;
            case 105:
                return <AddUsersNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã đặt: <span class="text-black">${message.usersAssigned.map(user => user.bizfullname).join("; ")}</span> với trai trò Admin`}/>;
            case 106:
                return <AddUsersNoti isLeave={true} content={`${(message.sender.bizfullname||message.sender.fullname)} đã xóa: <span class="text-black">${message.usersAssigned.map(user => user.bizfullname).join("; ")}</span> với vai trò Admin`}/>
            case 107:
                return <AddUsersNoti isLeave={true} content={`${(message.sender.bizfullname||message.sender.fullname)} đã rời khỏi cuộc hội thoại`}/>;
            case 108:
                return <PinMessageNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã ghim một tin nhắn`}/>
            case 109:
                return <PinMessageNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã bỏ ghim một tin nhắn`}/>
            case 111:
                return <PollNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã tham gia bình chọn: <span class="text-black">${message.poll.name}</span>`}/>
            case 112:
                return <PollNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã thay đổi bình chọn: <span class="text-black">${message.poll.name}</span>`}/>
            case 116:
            case 117:
                // eslint-disable-next-line no-case-declarations
                let didAccept = message.reminder.accepts.includes(message.sender._id)&&!message.reminder.rejects.includes(message.sender._id);
                if (!didAccept) {
                    didAccept = message.reminder.accepts.find(action => action._id === message.sender._id)&&!message.reminder.rejects.find(action => action._id === message.sender._id)
                }
                return <ReminderNoti content={`${(message.sender.bizfullname||message.sender.fullname)} đã ${didAccept?"xác nhận":"từ chối"} tham gia hội thoại: <span class="text-black">${message.reminder.content}</span>`}/>
            case 120:
                return <NpsNoti
                    content={`${(message.sender.bizfullname || message.sender.fullname)} đã tham gia NPS: <span class="text-black">${message.nps.service.name}</span> trong cuộc hội thoại`}/>
            case 121:
                return <NpsNoti
                    content={`${(message.sender.bizfullname || message.sender.fullname)} đã thay đổi NPS: <span class="text-black">${message.nps.service.name}</span> trong cuộc hội thoại`}/>
        }
    }
    return (
        <>
            {
                isLoading&&
                <div className={"absolute w-full flex items-center justify-center z-21 spinner-animation transition-all"}>
                    <div className={"bg-white rounded-full p-1"}>
                        <Oval color={"#0d6efd"} strokeWidth={3} secondaryColor={"#fff"} height={30} width={30}/>
                    </div>
                </div>
            }
            <div
                ref={conversationMessagesList}
                id={"conversation-messages-MessageList"}
                className={"flex-1 h-full items-center flex flex-col-reverse relative overflow-auto justify-start transition-all duration-150 pb-4"}
            >
                <div className={"w-full max-w-[60%] pt-[20px] px-[20px]"}>
                    {memoizedValue()}
                    {memoizedFakeValue()}
                </div>
                <div style={{ float:"left", clear: "both" }} ref={messagesEndRef} />
            </div>
            {
                userUnreadMessage()>0 &&
                <button
                    onClick={() => {
                        scrollToBottom()
                        markAsRead()
                    }}
                    className={"animate-bounce w-[48px] h-[48px] rounded-full absolute bottom-100px bg-cbs-item-message-other flex items-center justify-center right-40px z-10"}>
                    <img  src={ArrowDown} alt={"arrow down"}/>
                    <span
                        className={"absolute -top-1 -right-1 w-[24px] h-[24px] bg-positive rounded-full flex items-center justify-center text-white"}>{userUnreadMessage()}</span>
                </button>
            }
        </>
    )
}
