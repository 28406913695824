import ListMessageRepository from "apis/messages/message";
import {
    AddUserToConversationPayload,
    DeleteRevokeMessagePayload, GetNPSDetailPayload, GetNPSPleasantDetailData, GetNPSScoreDetailData, ItemMessage,
    ListMessageData,
    ListMessagePayload,
    PinnedMessagePayload, SearchMessageInConversationByIdPayload, SearchMessageInConversationByIdResponse,
    SendNormalMessagePayload,
    ShareMessageData,
    ShareMessagePayload, UpdateNPSData, UpdateNPSPayload, UpdatePinnedMessageData,
    UpdatePinnedMessagePayload, UpdatePollData, UpdatePollPayload,
    UpdateReminderMessageData,
    UpdateReminderMessagePayload, UpdateSeenMessageConversationPayload
} from "typings/apis/messages/listMessage";
import {setLoading, setListMessage, appendMessageToConversation, updateReminderData, setPinnedMessage, deleteMessageInConversations, revokeMessageInConversation} from "redux/reducer/messages/ListMessage";
import {setLoadingSearch, setListSearchMessage} from "redux/reducer/messages/SearchMessage";
import {setUnreadMessage} from "redux/reducer/messages/UnreadMessages";
import {useDispatch} from "react-redux";

type UseConversationType = {
    getListMessage: (params: ListMessagePayload) => Promise<ListMessageData>;
    sendNormalMessage: (params: SendNormalMessagePayload) => void;
    updateReminder: (params: UpdateReminderMessagePayload) => Promise<UpdateReminderMessageData>;
    shareMessageToConversation: (params: ShareMessagePayload) => Promise<ShareMessageData[]>;
    getListPinMessage: (params: PinnedMessagePayload) => void;
    updatePinMessage: (params: UpdatePinnedMessagePayload) => Promise<UpdatePinnedMessageData>;
    deleteMessage: (params: DeleteRevokeMessagePayload) => void;
    revokeMessage: (params: DeleteRevokeMessagePayload) => Promise<{data: ItemMessage[], error: boolean}>;
    updatePoll: (params: UpdatePollPayload) => Promise<UpdatePollData>;
    updateNPS: (params: UpdateNPSPayload) => Promise<UpdateNPSData>;
    addUserToConversation: (params: AddUserToConversationPayload) => Promise<{ data: any, error: boolean }>;
    goToMessagePosition: (params: SearchMessageInConversationByIdPayload, isFirstInit? : boolean, direction?: "front"|"back") => Promise<any>;
    updateSeenMessage: (params: UpdateSeenMessageConversationPayload) => Promise<any>;
    getReasonStatisticsNps: (params: GetNPSDetailPayload) => Promise<GetNPSPleasantDetailData>;
    getScoreStatisticsNps: (params: GetNPSDetailPayload) => Promise<GetNPSScoreDetailData>;
}

export const UseConversation = () : UseConversationType => {
    const dispatch = useDispatch();
    const currentUser = localStorage.getItem("currentUser")??"{}";
    const profile = JSON.parse(currentUser);

    const getListMessage = async (params: ListMessagePayload) => {
        dispatch(setLoading({conversationId: params.conversationID, data: true}));
        const {data} = await ListMessageRepository.getListMessage(params);
        dispatch(setListMessage({conversationId: params.conversationID, data: data, isFreshData: !params.lastestID}));
        const unreadMessages:string[] = [];
        data.listRecords.forEach(message => {
            let isUnread = true;
            (message.usersSeen??[]).forEach((user: string|{_id: string; bizfullname: string; fullname: string; image: string}) => {
                if (typeof user === "string") {
                    if (user === profile._id) {
                        isUnread = false;
                    }
                } else{
                    if (user._id === profile._id) {
                        isUnread = false;
                    }
                }
            });
            if (isUnread) {
                unreadMessages.push(message._id)
            }
        });
        dispatch(setUnreadMessage({unreadMessages, conversationId: params.conversationID}));
        dispatch(setLoading({conversationId: params.conversationID, data: false}));

        return new Promise<ListMessageData>((resolve, reject) => {
            resolve(data);
        });
    }

    const sendNormalMessage = async (params: SendNormalMessagePayload) => {
        const {data} = await ListMessageRepository.sendNormalMessage(params);
        data&&dispatch(appendMessageToConversation({
            conversationId: params.conversationID,
            messageId: data._id,
            messageData: {
                ...data,
                sender: profile
            }
        }));

        return data;
    }

    const updateReminder = async (params: UpdateReminderMessagePayload) => {
        const {data} = await ListMessageRepository.updateReminder(params);
        dispatch(updateReminderData({conversationId: params.conversationID, messageId: params.messageID, reminderData: data.infoReminder}));
        return new Promise<UpdateReminderMessageData>((resolve, reject) => {
            resolve(data);
        });
    }

    const shareMessageToConversation = async (params: ShareMessagePayload) => {
        const {data} = await ListMessageRepository.shareMessage(params);
        return new Promise<ShareMessageData[]>((resolve, reject) => {
            resolve(data);
        });
    }

    const getListPinMessage = async (params: PinnedMessagePayload) => {
        const {data} = await ListMessageRepository.getListPinMessage(params);
        dispatch(setPinnedMessage({conversationId: params.conversationID, listMessagesPinned: data.listRecords}))
        return data
    }

    const updatePinMessage = async (params: UpdatePinnedMessagePayload) => {
        const {data} = await ListMessageRepository.updatePinMessage(params);

        return new Promise<UpdatePinnedMessageData>((resolve, reject) => {
            resolve(data);
        });
    }

    const deleteMessage = async (params: DeleteRevokeMessagePayload) => {
        const {data} = await ListMessageRepository.deleteMessage(params);
        dispatch(deleteMessageInConversations({
            conversationId: params.conversationID,
            messageData: data
        }));

        return data;
    }

    const revokeMessage = async (params: DeleteRevokeMessagePayload) => {
        const {data,error} = await ListMessageRepository.revokeMessage(params);

        return new Promise<{data: ItemMessage[], error: boolean}>((resolve, reject) => {
            resolve({data, error});
        });
    }

    const updatePoll = async (params: UpdatePollPayload) => {
        const {data} = await  ListMessageRepository.updatePoll(params);

        return new Promise<UpdatePollData>((resolve, reject) => {
            resolve(data);
        });
    }

    const updateNPS = async (params: UpdateNPSPayload) => {
        const {data} = await  ListMessageRepository.updateNPS(params);

        return new Promise<UpdateNPSData>((resolve, reject) => {
            resolve(data);
        });
    }

    const addUserToConversation = async (params: AddUserToConversationPayload) => {
        const {data, error} = await ListMessageRepository.addUserToConversation(params);

        return new Promise<{ data: any, error: boolean }>((resolve, reject) => {
            resolve({data, error});
        });
    }

    const goToMessagePosition = async (params: SearchMessageInConversationByIdPayload, isFirstInit = true, direction = "front") => {
        dispatch(setLoadingSearch({conversationId: params.conversationID, data: true}));
        const {data} = await ListMessageRepository.goToMessagePosition(params);
        dispatch(setListSearchMessage({conversationId: params.conversationID, data: data, isFirstInit, direction}));
        dispatch(setLoadingSearch({conversationId: params.conversationID, data: false}));

        return new Promise<any>((resolve, reject) => {
            resolve(data);
        });
    }

    const updateSeenMessage = async (params: UpdateSeenMessageConversationPayload) => {
        const {data} = await ListMessageRepository.updateSeenMessage(params);
        dispatch(setUnreadMessage({unreadMessages: [], conversationId: params.conversationID}));

        return new Promise<any>((resolve, reject) => {
            resolve(data);
        });
    }

    const getReasonStatisticsNps = async (params: GetNPSDetailPayload) => {
        const {data} = await  ListMessageRepository.getReasonStatisticsNps(params);

        return new Promise<GetNPSPleasantDetailData>((resolve, reject) => {
            resolve(data);
        });
    }

    const getScoreStatisticsNps = async (params: GetNPSDetailPayload) => {
        const {data} = await  ListMessageRepository.getScoreStatisticsNps(params);

        return new Promise<GetNPSScoreDetailData>((resolve, reject) => {
            resolve(data);
        });
    }

    return {
        getListMessage,
        sendNormalMessage,
        updateReminder,
        shareMessageToConversation,
        getListPinMessage,
        updatePinMessage,
        deleteMessage,
        revokeMessage,
        updatePoll,
        updateNPS,
        addUserToConversation,
        goToMessagePosition,
        updateSeenMessage,
        getReasonStatisticsNps,
        getScoreStatisticsNps
    }
}