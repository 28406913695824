import React, { FC, useState, useEffect } from "react";

// ========== Utils ========== //
// ========== Hooks ========== //

export const PaymentHomePage: FC = () => {

    return (
        <div className="flex flex-1 flex-col overflow-hidden h-full">
            <p>PaymentHomePage</p>
        </div>
    )
}