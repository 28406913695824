import { useInfiniteQuery, useMutation } from "react-query";
import NotificationRepository from "apis/notification";
import { NotificationProps, UpdateNotificationPayload } from "typings/notification";

type UseNotification = {
    updateNotificationAsync: (payload: UpdateNotificationPayload) => Promise<any>;
    markAllReadAsync: () => Promise<any>;
    listNotifications: Array<NotificationProps>;
    isLoadingListNotification: boolean;
    refetchListNotification: () => void;
    nextPageListNotification: () => void;
    prevPageListNotification: () => void;
    totalRecordSendSuccess: number;
    totalRecord: number;
    hasNextPage: boolean | undefined;
};

export const useNotification = (params?: any, isFetch?: boolean) : UseNotification => {
    const { mutateAsync: updateNotificationAsync } = useMutation((payload: UpdateNotificationPayload) =>
        NotificationRepository.updateNotification(payload)
    );

    const { mutateAsync: markAllReadAsync } = useMutation(() =>
        NotificationRepository.markAllRead()
    );

    const {
        isLoading: isLoadingListNotification,
        refetch: refetchListNotification,
        data: dataListNotifications,
        fetchNextPage: nextPageListNotification,
        fetchPreviousPage: prevPageListNotification,
        hasNextPage: hasNextPage
    } =
        useInfiniteQuery(
            ["notifications--list", params],
            async ({ pageParam }: any) =>
            NotificationRepository.listNotification({
                lastestID: pageParam,
                ...params,
            }),
            {
                getNextPageParam: (params: any) => {
                    const nextCursor = params?.data?.nextCursor;
                    return nextCursor || undefined;
                },
                refetchOnWindowFocus: false,
                enabled: isFetch ?? false,
                cacheTime: 0,
                retry: false
            }
        );

    const listNotifications = []
        .concat(...(dataListNotifications?.pages || [])
        .map((d: any) => d.data?.listRecords))
        .filter(Boolean);

    const totalRecord = dataListNotifications?.pages[0]?.data?.totalRecord || 0;
    const totalRecordSendSuccess = dataListNotifications?.pages[0]?.data?.totalRecordSendSuccess || 0;

    return {
        updateNotificationAsync,
        markAllReadAsync,
        listNotifications,
        isLoadingListNotification,
        refetchListNotification,
        nextPageListNotification,
        prevPageListNotification,
        totalRecordSendSuccess,
        totalRecord,
        hasNextPage
    };
};