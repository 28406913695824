import { UpdateUser, UpdatePasswordUser, User, CheckCodeUser, CheckCodeResponse, AddFriendPayLoad, UnFriendPayLoad, UpdateAvatarUser, AcceptRequestFriend, RemoveRequestFriend } from "typings/user";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import UserRepository from "apis/user";
import { toast } from "react-toastify";

type UseUser = {
    listFriends: Array<User>,
    updateUser: (payload: UpdateUser) => Promise<void>,
    updatePasswordUser: (payload: UpdatePasswordUser) => Promise<void>,
    updateAvatarUser: (payload: UpdateAvatarUser) => Promise<void>,
    requestAddFriend: (payload: AddFriendPayLoad) => Promise<void>,
    requestUnFriend: (payload: UnFriendPayLoad) => Promise<void>,
    acceptRequestFriend: (payload: AcceptRequestFriend) => Promise<void>,
    removeRequestFriend: (payload: RemoveRequestFriend) => Promise<void>,
    checkCodeUser: (payload: CheckCodeUser) => Promise<CheckCodeResponse>,
    getCodeUser: () => Promise<CheckCodeResponse>,
    codeUser: any;
    refetchCode: () => void,
    totalRecordListFriends: number | undefined,
    totalPageListFriends: number | undefined,
    nextCursorListFriends: string,
    refetchListFriends: () => void,
    nextPageListFriends: () => void,
    hasNextPageListFriends: boolean | undefined
    // request friends
    isLoadingListRequestFriends: boolean,
    refetchListRequestFriends: () => void,
    listRequestFriends: Array<User>,
    nextPageListRequestFriends: () => void,
    prevPageListRequestFriends: () => void,
    hasNextPage: boolean | undefined,
    totalRecordRequest: number | undefined,
    totalPagesRequest: number | undefined,
};
export const useUser = (params?: any, pageParam = '' ) : UseUser => {
    const { mutateAsync: updateUserAsync } =
        useMutation((payload: UpdateUser) =>
        UserRepository.updateUser(payload)
        );

    const { mutateAsync: updatePasswordUserAsync } =
        useMutation((payload: UpdatePasswordUser) =>
        UserRepository.updatePasswordUser(payload)
    );
    const { mutateAsync: updateAvatarUserAsync } =
        useMutation((payload: UpdateAvatarUser) =>
        UserRepository.updateAvatarUser(payload)
    );
    const { mutateAsync: acceptRequestAsync } =
        useMutation((payload: AcceptRequestFriend) =>
        UserRepository.acceptRequestFriend(payload)
    );
    const { mutateAsync: removeRequestAsync } =
        useMutation((payload: RemoveRequestFriend) =>
        UserRepository.removeRequestFriend(payload)
    );
    
    const { mutateAsync: checkCodeUserAsync } =
        useMutation((payload: CheckCodeUser) =>
        UserRepository.checkCodeUser(payload)
    );

    const { mutateAsync: requestAddFriendAsync } =
        useMutation((payload: AddFriendPayLoad) =>
        UserRepository.requestAddFriend(payload)
    );

    const { mutateAsync: requestUnFriendAsync } =
        useMutation((payload: UnFriendPayLoad) =>
        UserRepository.requestUnFriend(payload)
    );

    const { mutateAsync: getCodeUserAsync } =
        useMutation(() =>
        UserRepository.getCodeUser()
    );

    const updateUser = async (payload: UpdateUser) => {
        try {
            const { error, message } = await updateUserAsync(payload);

            if (!error) {
                toast.success('Cập nhật thông tin thành công');
            } else {
                toast.error(message);
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const updatePasswordUser = async (payload: UpdatePasswordUser) => {
        try {
            const { error, data } = await updatePasswordUserAsync(payload);

            if (!error) {
                toast.success('Cập nhật mật khẩu thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const updateAvatarUser = async (payload: UpdateAvatarUser) => {
        try {
            const { error, data } = await updateAvatarUserAsync(payload);

            if (!error) {
                toast.success('Cập nhật ảnh đại diện thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const acceptRequestFriend = async (payload: AcceptRequestFriend) => {
        try {
            const { error, data } = await acceptRequestAsync(payload);

            if (!error) {
                toast.success('Đông ý kết bạn thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const removeRequestFriend = async (payload: RemoveRequestFriend) => {
        try {
            const { error, data } = await removeRequestAsync(payload);

            if (!error) {
                toast.success('Hủy lời mời kết bạn thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const getCodeUser = async () => {
        try {
            const { error, data } = await getCodeUserAsync();

            if (!error) {
                toast.success('Tạo mã thành công');
                return data
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const checkCodeUser = async (payload: CheckCodeUser) => {
        try {
            const { error, data } = await checkCodeUserAsync(payload);
            if (!error) {
                return data
            } else {
                if(data && data[0]) {
                    if(data[0].message === 'code_invalid') {
                        toast.error('Mã không tồn tại');
                    }  
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    const requestAddFriend = async (payload: AddFriendPayLoad) => {
        try {
            const { error, data } = await requestAddFriendAsync(payload);

            if (!error) {
                toast.success('Gửi lời mời kết bạn thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };
    
    const requestUnFriend = async (payload: UnFriendPayLoad) => {
        try {
            const { error, data } = await requestUnFriendAsync(payload);

            if (!error) {
                toast.success('Hủy kết bạn thành công');
            } else {
                if(data && data[0]) {
                    toast.error(data[0].message);
                }
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                toast.error(error);
            } else if (error instanceof Error) {
                toast.error(error.message);
            }
        }
    };

    // Get code
    const { data: dataUserCode, refetch: refetchCode } = useQuery("user_code", () => 
        UserRepository.getCodeUser(), {
            enabled: false
        });

    const codeUser = dataUserCode?.data;

    // Danh sách bạn bè của mình
    const {
        refetch: refetchListFriends,
        data: dataListFriends,
        fetchNextPage: nextPageListFriends,
        hasNextPage: hasNextPageListFriends
    } = useInfiniteQuery(
            ["list_friends", params?.keyword],
            async ({ pageParam }: any) =>
                UserRepository.listFriends({
                    lastestID: pageParam || "",
                    ...params,
                }),
            {
                getNextPageParam: (params: any) => {
                    const nextCursor = params?.data?.nextCursor;
                    return nextCursor || undefined;
                },
                refetchOnWindowFocus: false,
                enabled: true
            }
        );

    const listFriends = [].concat(...(dataListFriends?.pages || []).map((d: any) => d.data?.listRecords)).filter(Boolean);
    const totalRecordListFriends = dataListFriends?.pages[0]?.data?.totalRecord;
    const totalPageListFriends = dataListFriends?.pages[0]?.data?.totalPage;
    const nextCursorListFriends = dataListFriends?.pages[0]?.data?.nextCursor;
    // const {
    //     data,
    //     refetch: refetchListFriends
    // } = useQuery(
    //     ["list_friends", { pageParam }, params?.keyword],
    //     async () => {
    //         return UserRepository.listFriends({
    //             lastestID: pageParam || "",
    //             ...params,
    //         })
    //     },
    //     {
    //         keepPreviousData: true,
    //         refetchOnWindowFocus: false,
    //     }
    // );
    //
    // const listFriends = []
    //     .concat(
    //         ...(data?.pages || []).map((d: any) => d.data?.listRecords)
    //     ).filter(Boolean);
    // console.log(data)
    // console.log(listFriends)
    // const totalRecord = data?.data?.totalRecord
    // const totalPage = data?.data?.totalPage
    // const nextCursor = data?.data?.nextCursor

    // Danh sách lời mời kết bạn
    const infinityQuery = useInfiniteQuery(
        ['list_request_friends'],
        async ({ pageParam }: any) => {
            return UserRepository.listRequestFriends({
                ...params,
                lastestID: pageParam || "",
            })
        },
        {
            getNextPageParam: ({ data }: any) => {
                return data?.nextCursor || undefined;
            },
            refetchOnWindowFocus: false,
            enabled: true
        }
    )

    const {
        isLoading: isLoadingListRequestFriends,
        refetch: refetchListRequestFriends,
        data: listRequestFriendsFromAPI, // dataListContracts
        fetchNextPage: nextPageListRequestFriends,
        fetchPreviousPage: prevPageListRequestFriends,
        hasNextPage: hasNextPage
    } = infinityQuery;

    const listRequestFriends = []
        .concat(...(listRequestFriendsFromAPI?.pages || [])
        .map((d: any) => d.data?.listRecords))
        .filter(Boolean);
    const totalRecordRequest = listRequestFriendsFromAPI?.pages[0]?.data?.totalRecord;
    const totalPagesRequest = listRequestFriendsFromAPI?.pages[0]?.data?.totalPages;

    return {
        updateUser,
        updatePasswordUser,
        updateAvatarUser,
        requestAddFriend,
        requestUnFriend,
        acceptRequestFriend,
        removeRequestFriend,
        checkCodeUser,
        codeUser,
        getCodeUser,
        refetchCode,
        listFriends,
        totalRecordListFriends,
        totalPageListFriends,
        nextCursorListFriends,
        nextPageListFriends,
        hasNextPageListFriends,
        refetchListFriends,
        listRequestFriends,
        isLoadingListRequestFriends,
        refetchListRequestFriends,
        totalRecordRequest,
        totalPagesRequest,
        nextPageListRequestFriends,
        prevPageListRequestFriends,
        hasNextPage,
    }
}