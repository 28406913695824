import React, { FC, useState, useEffect } from "react";

// ========== Utils ========== //
// ========== Hooks ========== //

export const PaymentActivityPage: FC = () => {

    return (
        <div className="flex flex-1 flex-col overflow-hidden h-full">
            <p>PaymentActivityPage</p>
        </div>
    )
}