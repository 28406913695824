import { FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
	isModalOpen: boolean;
	toggle(): void;

	beforeEnter?(): void;
	afterEnter?(): void;
	beforeLeave?(): void;
	afterLeave?(): void;
}

export const ModalContainer: FC<Props> = ({ isModalOpen, toggle, children, ...rest }) => {
    const focusElementRef = useRef<HTMLDivElement>(null);

	return (
		<Transition appear show={isModalOpen} as={Fragment} {...rest}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50 overflow-y-auto"
				onClose={toggle}
                initialFocus={focusElementRef}
			>
				<div ref={focusElementRef} className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{children}
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
};