import register from './register.json';
import resetPassword from './reset-password.json';
import signIn from './sign-in.json';
import menu from './menu.json';

const authentication = {
    register,
    resetPassword,
    signIn,
    menu
};

export default authentication;