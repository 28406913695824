const postTypes = {
	// ======= MODAL =======
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_MODAL: "OPEN_MODAL",

	// ======= COMMENTS =======
	OPEN_COMMENT: "OPEN_COMMENT",
	
	// ======= IMAGES =======
	SET_INDEX_IMAGE:"SET_INDEX_IMAGE",
	SET_INFO_IMAGE:"SET_INFO_IMAGE"
};

export default postTypes;
