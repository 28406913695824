import React, {FC, useCallback} from "react";
import moment from "moment";
import {ItemMessage} from "typings/apis/messages/listMessage";
import {UseConversation} from "services/hooks/messages/useConversation";
import {setShouldStartListenEvent, clearListMessage} from "redux/reducer/messages/SearchMessage";
import {useDispatch} from "react-redux";

type Props = {
    message: ItemMessage
}
export const ListSearchMessagesItem:FC<Props> = ({message}) => {
    const currentUser = localStorage.getItem("currentUser")??"{}";
    const profileId = JSON.parse(currentUser)?._id;
    const dispatch = useDispatch();

    const getDate = useCallback(() => {
        const date = moment(message.modifyAt);
        if (date.isSame(new Date(), 'date')) {
            return date.format("HH:mm")
        }else if (date.isSame(new Date(), 'week')) {
            return date.format("dddd").split(" ").map((str:string) => str.charAt(0).toUpperCase() + str.slice(1)).join(" ")
        }else if (date.isSame(new Date(), 'year')) {
            return date.format("DD/MM")
        } else {
            return date.format("DD/MM/YYYY")
        }
    },[message.modifyAt]);

    const getMessageContent = useCallback(() => {
        if (message.usersDelete&&message.usersDelete.includes(profileId)) {
            return "Tin nhắn đã xóa";
        }
        switch (message.type) {
            case 0:
                return <p className={"flex items-center max-w-[250px]"}>
                    <span className={"truncate max-w-full"}>
                        {message.content.replaceAll(/[@]\[(.*?)\]\((.*?)\)/g, (replacement: string) => {
                            const matchs = (/[@]\[(.*?)\]\((.*?)\)/g).exec(replacement);
                            if (matchs) {
                                const fullName = matchs[1];
                                return `@${fullName}`;
                            } else {
                                return replacement;
                            }
                        })}
                    </span>
                </p>
            case 1:
                return <p className={"flex items-center"}>
                    [Hình ảnh]
                </p>;
            case 2:
                return <p className={"flex items-center"}>
                    [Tệp tin]
                </p>;
            default:
                return "[Thông báo]";
        }
    }, [message])
    const {goToMessagePosition} = UseConversation();

    const handleGoToMessage = (_id: string) => {
        dispatch(clearListMessage({conversationId: message.conversation}));
        goToMessagePosition({conversationID: message.conversation, messageID: message._id}).then(data => {
            setTimeout(() => {
                document.getElementById(`message-${message._id}`)?.scrollIntoView({block: "center", behavior: "smooth"});
                setTimeout(() => {
                    document.getElementById(`message-${message._id}`)?.classList.add("found-message");
                    dispatch(setShouldStartListenEvent({data: true, conversationId: message.conversation}));
                }, 250)
            }, 500)
        })
    }

    return (
        <div
            onClick={() => handleGoToMessage(message._id)}
            className={"group smooth-animation flex p-[10px] cursor-pointer rounded-lg z-1 hover:bg-gray-100"}>
            <img  className={"w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded-full overflow-hidden"} src={`${process.env.REACT_APP_URL_S3_USER}/${message?.sender?.image}`} alt={message.sender.bizfullname}/>
            <div className={"flex-1 flex flex-col justify-center ml-[10px]"}>
                <div className={"flex"}>
                    <div className={"flex-1 font-bold"}>
                        {message.sender.bizfullname}
                    </div>
                    <div className={"text-xs text-gray-500"}>
                        {getDate()}
                    </div>
                </div>
                <div className={"flex mt-1"}>
                    <div className={"flex-1 text-primary-gray"}>{getMessageContent()}</div>
                </div>
            </div>
        </div>
    )
}
